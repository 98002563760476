import React from 'react'
import kids from '../assets/images/PATENSCHAFT_1.jpg'
import { useStaticQuery, graphql } from 'gatsby'

const Contact = (props) =>  {

    const data = useStaticQuery(
        graphql`
          query {
            allContentfulLayoutFooter {
                edges {
                    node {
                        id
                        wordVisionTitle
                        wordVisionLink
                        email
                        tel
                        adresse
                        openingHours
                    }
                }
            }
          }
    `)

    return (
        <section id="contact">
            <div className="inner">
                <section>
                    <form method="post" action="https://mailthis.to/info@thestudyroom.de">
                        <div className="field half first">
                            <label htmlFor="name">Name</label>
                            <input type="text" name="name" id="name" />
                        </div>
                        <div className="field half first">
                            <label htmlFor="telephone">Telefon</label>
                            <input type="text" name="telephone" id="telephone" />
                        </div>
                        <div className="field">
                            <label htmlFor="email">Email</label>
                            <input type="text" name="email" id="email" />
                        </div>
                    
                        <div className="field">
                            <label htmlFor="message">Nachricht</label>
                            <textarea name="message" id="message" rows="6"></textarea>
                        </div>
                        <ul className="actions">
                            <li><input type="submit" value="Senden" className="special" /></li>
                        </ul>
                    </form>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2594.2625323563043!2d11.088139515571621!3d49.44175487934886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479f57a2a132e975%3A0x1959675a21c9eb02!2sStudyroom!5e0!3m2!1sde!2sde!4v1562747678068!5m2!1sde!2sde" width="100%" height="300" frameborder="0"  allowfullscreen=""></iframe>

                </section>
                <section className="split">
                    <section>
                        <div className="contact-method">
                            <h4>{data.allContentfulLayoutFooter.edges[0].node.wordVisionTitle}</h4>
                            <a href="/lesson/world-vision"><img src={kids} width="350px" /></a>
                        </div>
                    </section>
                    <section>
                        <div className="contact-method">
                            <span className="icon alt fa-envelope"></span>
                            <h3>Email</h3>
                            <a href="#">{data.allContentfulLayoutFooter.edges[0].node.email}</a>
                        </div>
                    </section>
                    <section>
                        <div className="contact-method">
                            <span className="icon alt fa-phone"></span>
                            <h3>Tel</h3>
                            <span>{data.allContentfulLayoutFooter.edges[0].node.tel}</span>
                        </div>
                    </section>
                    <section>
                        <div className="contact-method">
                            <span className="icon alt fa-home"></span>
                            <h3>Adresse</h3>
                            <span>{data.allContentfulLayoutFooter.edges[0].node.adresse}<br />
                            {data.allContentfulLayoutFooter.edges[0].node.openingHours}</span>
                        </div>
                    </section>
                </section>
            </div>
        </section>
    )
}

export default Contact
